<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" title="Update Job Checklist" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-card class="mb-2" :key="task.id" v-for="task in editedJob.tasks">
            <b-row>
              <b-col cols="10">
                <b-form-group label="Checklist Item Name">
                  <b-form-input type="text" v-model="task.name"/>
                </b-form-group>
              </b-col>
              <b-col class="d-flex flex-column justify-content-end" cols="2">
                <b-button block class="mb-3" @click.prevent="removeChecklistItem(task)" variant="danger"><fa-icon icon="trash" /></b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-checkbox class="align-self-center mb-3" v-model="task.is_header">Header</b-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="!task.is_header">
              <b-col cols="12">
                <b-form-group label="Checklist Item Description">
                  <b-form-textarea type="text" v-model="task.description"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-button block @click.prevent="addChecklistItem" variant="primary">Add Task...</b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveJob" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">Update Checklist</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';
// import CustomerSearch from '../../components/CustomerSearch.vue'
// import PropertySearch from '../../components/PropertySearch.vue'

export default {
//   components: { CustomerSearch, PropertySearch },
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedJob: {
        customer_id: null,
        property_id: null,
        started_at: new Date(),
        preferred_weekday: new Date().getDay(),
        preferred_time: '09:00:00',
        duration_min: '00:30:00',
        interval_days: 0,
        tasks: [{
          id: 0,
          name: '',
          description: '',
          is_header: false,
        }]
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    job: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.jobId && this.job) {
      this.loading = true;

      try {
        const job = await this.fetchSingle(this.$route.params.jobId);
        const duration = moment.duration(job?.duration_min ?? 0, 'minutes');
        this.editedJob = {
          tasks: [],
          ...job,
          duration_min: `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`,
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load job. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('jobs', ['fetch', 'fetchSingle', 'updateOrCreate']),
    addChecklistItem() {
      this.editedJob.tasks.push({
        id: null,
        name: '',
        description: '',
        is_header: false,
      });
    },
    removeChecklistItem(task) {
      this.editedJob.tasks.splice(this.editedJob.tasks.indexOf(task), 1);
    },
    closeModal() {
      this.editedJob = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
    },
    saveJob() {
      this.saving = true;
      this.updateOrCreate({
        ...this.editedJob,
        type: this.editedJob.interval_days === 0 ? 'single': 'recurring',
        duration_min: moment.duration(this.editedJob.duration_min).asMinutes(),
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    job(value) {
      const duration = moment.duration(value?.duration_min ?? 0, 'minutes')
      this.editedJob = {
        tasks: [],
        ...value,
        duration_min: `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`,
      };
    }
  }
}
</script>

<style>

</style>
