<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-job-checklist id="edit-job-checklist" :job="selectedJob" />
    <edit-job @hidden="selectedJob = undefined" id="edit-job" :job="selectedJob" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: 'jobs.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-2">
        <b-col cols="12" md="9">
          <b-form-input debounce="500" @update="load(true)" placeholder="Search" type="text" v-model="query" />
        </b-col>
        <b-col cols="12" md="3">
          <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown" /><fa-icon class="ml-2" icon="chevron-up" v-else /></b-button>
        </b-col>
      </b-row>
      <b-card v-if="filtersShown">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="scheduledEnabled"><label class="mb-0" for="scheduled">Scheduled</label></b-checkbox>
              </template>
              <b-form-select :disabled="!scheduledEnabled" id="scheduled" v-model="selectedScheduled" :options="['Scheduled', 'Unscheduled']" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="completeEnabled"><label class="mb-0" for="complete">Complete</label></b-checkbox>
              </template>
              <b-form-select :disabled="!completeEnabled" id="complete" v-model="selectedComplete" :options="['Complete', 'Incomplete']" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="dateEnabled"><label class="mb-0" for="date">Date</label></b-checkbox>
              </template>
              <b-form-datepicker :disabled="!dateEnabled" id="date" v-model="selectedDate" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center mt-2 py-5" v-if="!loading && data.length === 0">
          <p class="h3 font-weight-bold">No jobs found.</p>
          <p class="text-muted mb-0">Could not find any jobs that you have access to view.</p>
      </b-card>
      <b-list-group class="mb-4 mt-2" v-show="!loading">
        <b-table sortable responsive outlined :items="formattedData" :fields="['Location/Property', {key: 'date', sortable: true}, 'complete', 'scheduled',  'actions']" @sort-changed="onSortChange">
          <template #cell(date)="row">
            {{ moment(row.item.date).format('DD/MM/YYYY @ HH:mm') }}
          </template>
          <template #cell(actions)="row">
            <div class="align-self-center d-flex flex-md-row flex-column">
              <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="editChecklist(row.item.id)" variant="light" v-b-popover.top.hover="`Edit Checklist`" v-if="user.type !== 'cleaner'"><fa-icon icon="tasks" /></b-button>
              <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(row.item.id)" variant="primary" v-b-popover.top.hover="`Edit`" v-if="user.type !== 'cleaner'"><fa-icon icon="pen" /></b-button>
              <b-button class="mb-1 mb-md-0 ml-md-1" :to="{ name: 'jobs.details', params: { jobId: row.item.id } }" variant="primary" v-b-popover.top.hover="`View extra details`"><fa-icon icon="arrow-right" /></b-button>
              <b-button class="mb-1 mb-md-0 ml-md-1" variant="danger" v-b-popover.top.hover="`Delete`" v-if="user.type !== 'cleaner'"><fa-icon icon="trash" /></b-button>
            </div>
          </template>
        </b-table>
      </b-list-group>
      <b-pagination-nav :link-gen="paginationLinkGen" v-model="pageNo" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditJob from './Modals/EditJob.vue'
import EditJobChecklist from './Modals/EditJobChecklist.vue'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { EditJob, EditJobChecklist, StickyHeader },
  data() {
    if (this.$route.query.page) {
      sessionStorage.setItem("jobs:pageNo", this.$route.query.page);
    }

    return {
      query: sessionStorage.getItem("jobs:query") ?? '',
      pageNo: sessionStorage.getItem("jobs:pageNo") ?? 1,
      selectedJob: {},
      filtersShown: sessionStorage.getItem("jobs:filterShown") ?? false,
      scheduledEnabled: sessionStorage.getItem("jobs:scheduledEnabled") ?? false,
      completeEnabled: sessionStorage.getItem("jobs:completeEnabled") ?? false,
      dateEnabled: sessionStorage.getItem("jobs:dateEnabled") ?? false,
      selectedScheduled: sessionStorage.getItem("jobs:selectedScheduled") ?? undefined,
      selectedComplete: sessionStorage.getItem("jobs:selectedComplete") ?? undefined,
      selectedDate: sessionStorage.getItem("jobs:selectedDate") ?? undefined,
      formattedData: [],
      sortBy: {
        column: '',
        desc: false
      }
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('jobs', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('jobs', ['fetch']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'jobs', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'jobs', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedJob = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'jobs.update' && this.$route.params.jobId === id)) {
        this.$router.push({ name: 'jobs.update', params: { jobId: id } });
      }
    },
    editChecklist(id) {
      this.selectedJob = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'jobs.checklist' && this.$route.params.jobId === id)) {
        this.$router.push({ name: 'jobs.checklist', params: { jobId: id } });
      }
    },
    async load(reset = false) {
      if (reset) {
        sessionStorage.removeItem("jobs:pageNo");
        this.pageNo = 1;
      }

      await this.fetch(Object.fromEntries(Object.entries({
        query: this.query,
        page: this.pageNo ?? 1,
        scheduled: this.scheduledEnabled ? this.selectedScheduled : undefined,
        complete: this.completeEnabled ? this.selectedComplete : undefined,
        date: this.dateEnabled ? this.selectedDate : undefined,
        sortBy: this?.sortBy?.column ?? undefined,
        sortByDesc: this?.sortBy?.desc ?? undefined,
      }).filter(([,v]) => v !== undefined)));
    },
    onSortChange(data) {
      this.$router.replace(this.paginationLinkGen(1));

      this.sortBy = {
        column: data.sortBy,
        desc: data.sortDesc
      }

      this.load();

      return false;
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    async filtersShown(data) {
      sessionStorage.setItem("jobs:filtersShown", data)
      await this.load();
    },
    async scheduledEnabled(data) {
      sessionStorage.setItem("jobs:scheduledEnabled", data)
      await this.load()
    },
    async completeEnabled(data) {
      sessionStorage.setItem("jobs:completeEnabled", data)
      await this.load()
    },
    async dateEnabled(data) {
      sessionStorage.setItem("jobs:dateEnabled", data)
      await this.load()
    },
    async selectedScheduled(data) {
      sessionStorage.setItem("jobs:selectedScheduled", data)
      await this.load();
    },
    async selectedComplete(data) {
      sessionStorage.setItem("jobs:selectedComplete", data)
      await this.load()
    },
    async selectedDate(data) {
      sessionStorage.setItem("jobs:selectedDate", data)
      await this.load()
    },

    data(data) {
      this.formattedData = map(data, job => ({
        id: job.id,
        'Location/Property': job?.customer?.name ?? job?.property?.name ?? 'Unknown',
        date: job.started_at,
        complete: job.completed_at ? 'Yes' : 'No',
        scheduled: job.rota ? 'Yes' : 'No'
      }))
    },
    query(data) {
      sessionStorage.setItem("jobs:query", data);
    },
    pageNo(data) {
      sessionStorage.setItem("jobs:pageNo", data);
      this.load();
    },
  }
}
</script>

<style>

</style>
